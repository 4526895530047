import React from 'react';
import { withRouter } from 'react-router';
import fire from '../Firebase';
import './login.css';
import logo from '../../logo.png';

const INITIAL_STATE = {
    email: '',
    pass: '',
    err: null
}

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        fire.auth().signInWithEmailAndPassword(this.state.email, this.state.pass)
            .then(res => {
                console.log('login success');
                this.props.history.push('/');
            })
            .catch(err => {
                // this.setState ({err});
                alert(err.message);
            });
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        return (
            <div className="container-fluid">
                <br />
                <br />
                <br />

                <br />
                <br />

                <div className="card col-lg-6 offset-lg-3 border-dark bg-light">

                    <div className="card-header text-white login-header" style={{ backgroundColor: '#103D5D' }}>
                        <p className="text-center">
                            <img className="text-center" src={logo} />
                        </p>
                        {/* <h4 className="card-title text-center">  eSaloon</h4>
                        <h5 className="card-subtitle text-center">Online Saloon System</h5>
                        <br /> */}
                        <h6 className="card-subtitle text-center">Login to get started</h6>
                    </div>
                    <div className="card-body">
                        <br />
                        <form onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <label>Email address</label>
                                <input type="email" name="email" value={this.state.email} onChange={this.handleChange} className="form-control" placeholder="Enter email" />
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input type="password" name="pass" value={this.state.pass} onChange={this.handleChange} className="form-control" placeholder="Password" />
                            </div>
                            <div className="text-danger">
                                {this.state.err}
                            </div>
                            <button type="submit" className="btn btn-primary">Sign In</button>
                        </form>
                    </div>
                    <br />
                    <p className="text-muted text-right">&copy;Boolean Apps 2019</p>
                </div>
            </div>
        )
    }
}

export default withRouter(Login);