import React from 'react';
import fire from '../Firebase';

const INITIAL_STATE = {
    users: []
}

class UserManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount(){
        this.loadUser();
    }
    loadUser() {
        fire.database().ref(`stores/${this.props.currentStoreId}/users`).on('value', snap => {
            var payload = snap.val();
            if (payload === null) {
                this.setState({ users: [] });
            }
            console.log(payload);
        });
    }
    render() {
        const { users } = this.state;
        return (
            <div>
                <ul className="list-group">
                    {
                        users.length === 0 ?
                            <li className="list-group-item">No Users Found</li> :
                            users.map(user => <li className="list-group-item">{user}</li>)
                    }
                </ul>
            </div>
        )
    }
}

export default UserManagement;