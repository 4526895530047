import React from 'react';
import { Route } from 'react-router-dom';
import Nav from '../Nav';
import Home from '../Home';
import GuestLogReport from '../Reports/guestLogReport';
import fire from '../Firebase';
import Settings from '../Settings';
import Admin from '../Admin';

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            currentStoreName: ''
        };
    }

    componentDidMount() {
        this.loadStoreInfo();
    }

    loadStoreInfo() {
        fire.database().ref(`stores/${this.state.user.defaultStore}/info`)
            .on('value', snap => {
                var val = snap.val();
                this.setState({ currentStoreName: val.name });
            })
    }

    render() {
        const { match } = this.props;
        return (
            <div>
                <Nav currentStoreName={this.state.currentStoreName} user={this.state.user} />
                <Route path={`${match.path}logs`}
                    render={(props) => <GuestLogReport {...props} currentStoreId={this.state.user.defaultStore} />} />
                <Route path={`${match.path}settings`}
                    render={(props) => <Settings {...props} currentStoreId={this.state.user.defaultStore} />} />
                <Route path={`${match.path}admin`}
                    render={(props) => <Admin {...props} currentStoreId={this.state.user.defaultStore} user={this.state.user} />} />
                <Route exact path={match.path}
                    render={(props) => <Home {...props} currentStoreId={this.state.user.defaultStore} />} />
            </div>
        )
    }
}

export default Welcome;