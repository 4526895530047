import React from 'react';
import UserManagement from './user_mgmt';


class BusinessAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 1 };
    }
    handleTabChange = (index) => {
        this.setState({ activeIndex: index });
    }
    render() {
        const { activeIndex } = this.state;
        const { user, currentStoreId } = this.props;
        return (
            <div>
                <h5>Settings</h5>
                <br />
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={"nav-link " + (activeIndex === 1 ? 'active' : '')} onClick={() => this.handleTabChange(1)}>Users</a>
                    </li>
                    <li className="nav-item">
                        <a className={"nav-link " + (activeIndex === 2 ? 'active' : '')} onClick={() => this.handleTabChange(2)}>Stores</a>
                    </li>
                </ul>
                <br />
                {activeIndex === 1 ? <UserManagement currentStoreId={currentStoreId} user={user} /> : null}

            </div>
        )
    }
}

export default BusinessAdmin;