import React from 'react';
import fire from '../Firebase';
import NumberFormat from 'react-number-format';

const INITIAL_STATE = {
    services: [],
    service: {
        name: '',
        price: ''
    }
}
class Services extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.props.setActiveTab(2);
    }

    servicesRef = () => fire.database().ref(`stores/${this.props.currentStoreId}/services`);

    componentDidMount() {
        this.loadInfo();
    }

    loadInfo() {
        this.servicesRef().on('value', snap => {
            var payload = snap.val();
            if (payload === null) {
                this.setState({ ...INITIAL_STATE });
            } else {
                var services = Object.keys(payload).map(key => {
                    return { id: key, ...payload[key] }
                });
                this.setState({ services: services });
            }
        })
    }

    saveInfo() {
        this.servicesRef().push(this.state.service)
            .then(res => this.setState({ service: { ...INITIAL_STATE.service } }))
            .catch(err => alert(err.message));
    }

    handleChange = e => {
        this.setState({ service: { ...this.state.service, [e.target.name]: e.target.value } });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.saveInfo();
    }
    
    handleRemove = (id) => {
        console.log(id);
        this.servicesRef().child(id).set(null)
        .catch(err => alert(err.message));
    }

    render() {
        const { services, service } = this.state;
        return (
            <div>
                <div className="input-group">
                    <input type="text" className="form-control" name="name" value={service.name} placeholder="Service" onChange={this.handleChange} />
                    <NumberFormat className="form-control" type='tel' thousandSeparator={true} prefix={'$'}
                        placeholder='Price' name="price" value={service.price}
                        onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            this.setState({ service: { ...service, price: value } });
                        }} />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button" disabled={service.name === '' || service.price === ''} onClick={this.handleSubmit}>Add</button>
                    </div>
                </div>
                <br />
                <ul className="list-group">
                    {
                        services.map(service =>
                            <li className='list-group-item' key={service.id}>{service.name} -
                                <NumberFormat value={service.price} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                <button className="btn btn-xs btn-danger float-right" onClick={() => this.handleRemove(service.id)}>
                                    <span className="fa fa-trash"></span>
                                </button>
                            </li>)
                    }
                </ul>
            </div>
        )
    }
}

export default Services;