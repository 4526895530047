import React from 'react';
import Waiting from '../Waiting';
import Servicing from '../Servicing';
import Today from '../Today';

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStoreId: props.currentStoreId,
        }
    }

    render() {
        return (
            <div className="container ">
                <div className="row">
                    <div className="col-md-5">
                        <Waiting currentStoreId={this.state.currentStoreId} />
                    </div>
                    <div className="col-md-5">
                        <Servicing currentStoreId={this.state.currentStoreId} />
                    </div>
                    <div className="col-md-2">
                        <Today currentStoreId={this.state.currentStoreId} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Home;