import React from 'react';
import fire from '../Firebase';
import NumberFormat from 'react-number-format';

const INITIAL_STATE = {
    name: '',
    phone: '',
    address: 'asf',
    hours: ''
}
class Store extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.props.setActiveTab(1);
    }

    componentDidMount(){
        this.loadInfo();
    }

    loadInfo() {
        fire.database().ref(`stores/${this.props.currentStoreId}/info`).once('value', snap => {
            var payload = snap.val();
            this.setState({...payload });
        })
    }

    saveInfo() {
        var info = `stores/${this.props.currentStoreId}/info`;
        var updates = {};
        updates[`${info}/name`] = this.state.name;
        updates[`${info}/phone`] = this.state.phone;
        updates[`${info}/address`] = this.state.address;
        updates[`${info}/hours`] = this.state.hours;
        fire.database().ref().update(updates)
            .catch(err => alert(err.message));
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.saveInfo();
    }

    isFormValid = () => {
        const { name, phone, address, hours } = this.state;
        return name.trim().length > 0 &&
            phone.trim().length > 0 &&
            address.trim().length > 0 &&
            hours.trim().length > 0;
    }

    render() {
        const { name, phone, address, hours } = this.state;
        return (
            <div>
                <form>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" name="name" value={name} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Phone Number</label>
                        <div className="col-sm-10">
                            <NumberFormat className="form-control" type='tel' format="(###) ### ####"
                                placeholder='(xxx) xxx xxxx' name="phone" value={phone}
                                onValueChange={(values) => {
                                    const { formattedValue, value } = values;
                                    this.setState({ phone: value });
                                }} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Address</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" name="address" value={address} onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Hours</label>
                        <div className="col-sm-10">
                            <textarea className="form-control" rows="5" name="hours" value={hours} onChange={this.handleChange} />
                        </div>
                    </div>
                    <br />
                    <button className="btn btn-primary" disabled={!this.isFormValid()} onClick={this.handleSubmit}>Save</button>
                </form>
            </div>
        )
    }
}

export default Store;