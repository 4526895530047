import React from 'react';
import { Link, Route } from 'react-router-dom';
import Store from './store';
import Services from './services';
import Stylists from './stylists';

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeIndex: 1, currentStoreId: props.currentStoreId };
    }

    setActiveTab = (index) => {
        this.setState({ activeIndex: index });
    }

    render() {
        const { activeIndex, currentStoreId } = this.state;
        const { match } = this.props;
        return (
            <div className="container">
                <h5>Settings</h5>
                <br />
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link className={"nav-link " + (activeIndex === 1 ? 'active' : '')} to={`${match.path}/store`} onClick={() => this.setActiveTab(1)}>Store</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={"nav-link " + (activeIndex === 2 ? 'active' : '')} to={`${match.path}/services`} onClick={() => this.setActiveTab(2)}>Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link className={"nav-link " + (activeIndex === 3 ? 'active' : '')} to={`${match.path}/stylists`} onClick={() => this.setActiveTab(3)}>Stylists</Link>
                    </li>
                </ul>
                <br />

                <Route path={`${match.path}/store`}
                    render={(props) => <Store {...props} currentStoreId={currentStoreId} setActiveTab={this.setActiveTab} />} />

                <Route path={`${match.path}/services`}
                    render={(props) => <Services {...props} currentStoreId={currentStoreId} setActiveTab={this.setActiveTab}/>} />

                <Route path={`${match.path}/stylists`}
                    render={(props) => <Stylists {...props} currentStoreId={currentStoreId}  setActiveTab={this.setActiveTab}/>} />

                <Route exact path={`${match.path}`}
                    render={(props) => <Store {...props} currentStoreId={currentStoreId} setActiveTab={this.setActiveTab} />} />
            </div>

        )
    }
}

export default Settings;