import React from 'react';
import fire from '../Firebase';

const INITIAL_STATE = {
    stylists: [],
    name: ''
}
class Stylists extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.props.setActiveTab(3);
    }

    stylistsRef = () => fire.database().ref(`stores/${this.props.currentStoreId}/stylists`);

    componentDidMount(){
        this.loadStylists();
    }
    
    loadStylists() {
        this.stylistsRef().on('value', snap => {
            var payload = snap.val();
            if (payload == null) {
                this.setState({ ...INITIAL_STATE });
            } else {
                var stylists = Object.keys(payload).map(key => {
                    return { id: key, ...payload[key] };
                });
                this.setState({ stylists: stylists });
            }
        })
    }

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }

    save = () => {
        this.stylistsRef().push({name:this.state.name})
            .then(res => this.setState({ name: '' }))
            .catch(err => alert(err.message));
    }

    remove = (id) => {
        this.stylistsRef().child(id).set(null)
            .catch(err => alert(err.message));
    }

    render() {
        const { stylists, name } = this.state;
        return (
            <div>
                <div className="input-group">
                    <input type="text" className="form-control" name="name" value={name} placeholder="Enter Stylist Name" onChange={this.handleChange} />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button" disabled={name === ''} onClick={this.save}>Add</button>
                    </div>
                </div>
                <br />
                <ul className="list-group">
                    {
                        stylists.map(stylist =>
                            <li className='list-group-item' key={stylist.id}>{stylist.name}
                                <button className="btn btn-xs btn-danger float-right" onClick={() => this.remove(stylist.id)}>
                                    <span className="fa fa-trash"></span>
                                </button>
                            </li>)
                    }
                </ul>
            </div>
        )
    }
}

export default Stylists;