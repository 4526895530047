import React from 'react';
import { Redirect } from 'react-router-dom';
import BusinessAdmin from './businessAdmin';

class Admin extends React.Component {

    render() {
        const { currentStoreId, user } = this.props;
        return (
            <div className="container ">
                {(user.role !== 'business_admin' && user.role !== 'admin') ? <Redirect to="/404" /> :
                 <BusinessAdmin currentStoreId={currentStoreId} user={user} />

                    // (user.role === 'business_admin') ? <BusinessAdmin user={user} /> :
                    //     <h5>Admin</h5>

                }

            </div>
        )
    }
}

export default Admin;