import React from 'react';
import fire from '../Firebase';
import NoGuests from '../NoGuest';
import ServicingGuest from './guest';
import ServiceModal from './serviceModal';

const INITIAL_STATE = {
    guests: [],
    selectedGuest: {}
}

class Servicing extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        fire.database().ref(`stores/${this.props.currentStoreId}/servicing`).on('value', snap => {
            var guests = [];
            var payload = snap.val();
            if (payload === null) {
                this.setState({ guests: [] });
                return;
            }
            Object.keys(payload).forEach(key => {
                var guest = { id: key, ...payload[key] };
                guests.push(guest);
                this.setState({ guests: guests });
            });
        });
    }

    selectGuest = (guest) => {
        this.setState({ selectedGuest: guest });
    }

    render() {
        return (
            <div className="">
                <div className="alert alert-dark">
                    <h5>Servicing</h5>
                </div>
                {
                    this.state.guests.length === 0 ?
                        <NoGuests message="No guests being serviced!!" /> :
                        this.state.guests.map(guest => <ServicingGuest key={guest.id} guest={guest} onClick={this.selectGuest} />)
                }
                <ServiceModal currentStoreId={this.props.currentStoreId} guest={this.state.selectedGuest} />
                <br />
                <br />
            </div>
        )
    }
}

export default Servicing;