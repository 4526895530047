import firebase from 'firebase';

const config = {
    apiKey: "AIzaSyB2oQPvOr0ne-jSoxvwM5BRHzFgdlM9gQA",
    authDomain: "echeckin-57a2c.firebaseapp.com",
    databaseURL: "https://echeckin-57a2c.firebaseio.com",
    projectId: "echeckin-57a2c",
    storageBucket: "echeckin-57a2c.appspot.com",
    messagingSenderId: "495821148978"
};
const fire = firebase.initializeApp(config);
export default fire;