import React from 'react';
import moment from 'moment';

class LastVisit extends React.Component {
    render() {
        return (
            this.props.lastVisit.name ?
                <div className="">
                    <strong> <i className="fa fa-user-o"></i>  Hurray!! Return Customer!!</strong>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <dl className="dl-horizontal">
                                <dt>Service : </dt>
                                <dd>{this.props.lastVisit.service}</dd>
                            </dl>
                        </div>
                        <div className="col">
                            <dl className="dl-horizontal">
                                <dt>Stylist : </dt>
                                <dd> {this.props.lastVisit.stylist} </dd>
                            </dl>
                        </div>
                        <div className="col">
                            <dl className="dl-horizontal">
                                <dt>On : </dt>
                                <dd>{moment(this.props.lastVisit.inAt).calendar()} </dd>
                            </dl>
                        </div>
                    </div>
                    <dl className="dl-horizontal">
                        <dt>Notes : </dt>
                        <dd>{this.props.lastVisit.notes || ''} </dd>
                    </dl>
                </div>
                :
                <div className="alert alert-success">
                    <strong> <i className="fa fa-user-plus"></i>  Hurray!! New Customer.</strong>
                </div>
        )
    }
}
export default LastVisit;














