import React from 'react';
import moment from 'moment';

class ServicingGuest extends React.Component {
    render() {
        return (
            <div>
                <p></p>
                <div className="card" data-toggle="modal" data-target="#ServiceModal" onClick={() => this.props.onClick(this.props.guest)}>
                    <div className="card-body">
                        <div className="float-right">
                            {this.props.guest.service} <br />
                            {this.props.guest.stylist} <br />
                            <span className="text-muted">
                                {moment(this.props.guest.servicedAt).fromNow()}
                            </span>
                        </div>
                        <h5 className="card-title">{this.props.guest.name}</h5>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServicingGuest;