import React from 'react';
import {Link} from 'react-router-dom';
import queryString from 'query-string'
import moment from 'moment';
import fire from '../Firebase';

const INITIAL_STATE = {
    params: {},
    logs: []
}
class GuestLogReport extends React.Component {
    constructor(props) {
        super(props);
        var params = queryString.parse(this.props.location.search);
        this.state = { ...INITIAL_STATE, params: { ...params } };

        params.start = this.getDate(params.start);
        params.end = this.getDate(params.end);
        params.start.setHours(0, 0, 0, 0);
        params.end.setHours(23, 59, 59, 59);
        this.state = { ...INITIAL_STATE, params: { ...params } };

        this.loadLogs();
    }

    getDate(dateString) {
        if (isNaN(dateString)) return new Date();
        if (dateString.length > 8) return new Date();
        var mm = dateString.slice(0, 2);
        var dd = dateString.slice(2, 4);
        var yyyy = dateString.slice(4);
        var date = new Date(yyyy, mm - 1, dd);
        return date;
    }

    weekStart = () => {
        var date = new Date();
        date.setHours(0, 0, 0, 0);
        var sun = new Date(date);
        sun.setDate(sun.getDate() - sun.getDay());
        var mm = ("0" + (sun.getMonth() + 1)).slice(-2);
        var dd = ("0" + sun.getDate()).slice(-2);
        var yyyy = sun.getFullYear();
        return `${mm}${dd}${yyyy}`;
    }

    monthStart = (num) => {
        var date = new Date(), first = new Date();
        var y = date.getFullYear();
        var m = date.getMonth();
        first.setFullYear(y, m - num + 1, 1);
        var mm = ("0" + (first.getMonth() + 1)).slice(-2);
        var dd = ("0" + first.getDate()).slice(-2);
        var yyyy = first.getFullYear();
        return `${mm}${dd}${yyyy}`;

    }

    loadLogs() {
        fire.database().ref(`stores/${this.props.currentStoreId}/guestLogs`)
            .orderByChild('inAt').startAt(this.state.params.start.getTime()).endAt(this.state.params.end.getTime())
            .on('value', snap => {
                var payload = snap.val();
                if (payload === null) {
                    this.setState({ logs: [] });
                } else {
                    var logs = Object.keys(payload).map(key => {
                        return { id: key, ...payload[key] };
                    });
                    logs.reverse();
                    this.setState({ logs: logs });
                }
            })
    }

    row = (log) => {
        var inAt = moment(log.inAt);
        var servicedAt = moment(log.servicedAt);
        var outAt = moment(log.outAt);
        var waitTime = moment.duration(servicedAt.diff(inAt));
        var serviceTime = moment.duration(outAt.diff(servicedAt));

        return <tr key={log.id}>
            <td>{log.name}</td>
            <td>{log.phone}</td>
            <td>{moment(log.inAt).format('LL')}</td>
            <td>{log.service}</td>
            <td>{log.stylist}</td>
            <td>{inAt.format('LT')}</td>
            <td>{servicedAt.format('LT')}</td>
            <td>{outAt.format('LT')}</td>
            <td>{waitTime.humanize()}</td>
            <td>{serviceTime.humanize()}</td>
        </tr>;
    }

    render() {
        const { params, logs } = this.state;
        return (
            <div className="container">
                <div>
                    <div className="float-right text-right">
                        <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Date Range
                            </button>
                            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item" href="logs">Today</a>
                                <a className="dropdown-item" href={`logs?start=${this.weekStart()}`}>This Week</a>
                                <a className="dropdown-item" href={`logs?start=${this.monthStart(1)}`}>This Month</a>
                                <a className="dropdown-item" href={`logs?start=${this.monthStart(3)}`}>Past 3 Months</a>
                            </div>
                            <br />
                            {moment(params.start).format('LL')} - {moment(params.end).format('LL')}
                        </div>
                    </div>
                    <h5>Guest Book <br />
                        <small>{logs.length} guests</small>
                    </h5>
                    <br />
                    <table className="table table-striped ng-scope">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th>Date</th>
                                <th>Service</th>
                                <th>Stylist</th>
                                <th>Checked-In At</th>
                                <th>Serviced At</th>
                                <th>Checked-Out At</th>
                                <th>Wait Time</th>
                                <th>Service Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                logs.length === 0 ?
                                    <tr>
                                        <td colSpan="10">No records found. Please check dates !!</td>
                                    </tr> :
                                    logs.map(log => this.row(log))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default GuestLogReport;