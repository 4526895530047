import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import fire from '../Firebase';
import Login from '../Login/';
import Welcome from '../Welcome';

const INITIAL_STATE = { loading: true, authenticated: false, user: null }

class App extends React.Component {

  constructor() {
    super();
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.authListener();
  }

  authListener = () => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({ authenticated: true });
        this.loadUser(user.uid);
      } else {
        this.setState({ loading: false });
      }
    });
  }

  loadUser(uid) {
    fire.database().ref(`users/${uid}`).once('value', snap => {
      this.setState({
        user: snap.val(),
        loading: false
      });
    });
  }


  render() {
    const { loading, authenticated, user } = this.state;

    // fb takes time to load user auth
    if (loading) {
      return <p>Loading..</p>;
    }

    return (
      <Router>
        <div>
          {
            this.state.user ? <Route path='/' render={(props) => <Welcome {...props} user={user} />} /> : <Login />
          }
        </div>
      </Router >
    );
  }
}

export default App;
