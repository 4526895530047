import React from 'react';
import fire from '../Firebase';
import moment from 'moment';

const INITIAL_STATE = {
    guest: {}
}

class ServiceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.guest !== this.props.guest) {
            this.setState({ guest: this.props.guest });
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({ guest: { ...this.props.guest, [e.target.name]: e.target.value } });
    }

    checkout = () => {
        var store = `stores/${this.props.currentStoreId}`;
        var guest = { outAt: new Date().getTime(), ...this.state.guest };
        var updates = {};

        // copy to guest logs
        updates[`${store}/guestLogs/${this.state.guest.id}`] = guest;
        
        // update guests
        updates[`${store}/guests/${this.state.guest.phone}/name`] = guest.name;
        updates[`${store}/guests/${this.state.guest.phone}/id`] = guest.id;
        updates[`${store}/guests/${this.state.guest.phone}/phone`] = guest.phone;
        updates[`${store}/guests/${this.state.guest.phone}/inAt`] = guest.inAt;
        updates[`${store}/guests/${this.state.guest.phone}/servicedAt`] = guest.servicedAt;
        updates[`${store}/guests/${this.state.guest.phone}/outAt`] = guest.outAt;
        updates[`${store}/guests/${this.state.guest.phone}/service`] = guest.service;
        updates[`${store}/guests/${this.state.guest.phone}/stylist`] = guest.stylist;
        if(guest.notes)
            updates[`${store}/guests/${this.state.guest.phone}/notes`] = guest.notes;
        
        //update visits
        updates[`${store}/guests/${this.state.guest.phone}/visits/${this.state.guest.id}`] = true;

        //remove from servicing 
        updates[`${store}/servicing/${this.state.guest.id}`] = null;

        //simulataneously update all
        fire.database().ref().update(updates)
            .catch(err => {
                alert(err.message);
            });

        // fire.database().ref(`stores/${this.props.currentStoreId}/guestLogs/${this.state.guest.id}`)
        //     .set({
        //         outAt: new Date().getTime(),
        //         ...this.state.guest
        //     })
        //     .then(res => {
        //         return fire.database().ref(`stores/${this.props.currentStoreId}/guests/${this.state.guest.phone}`)
        //             .set
        //         this.remove();
        //     })
        //     .catch(err => {
        //         alert(err.message);
        //     });
    }

    remove = () => {
        fire.database().ref(`stores/${this.props.currentStoreId}/servicing/${this.state.guest.id}`)
            .remove()
            .catch(err => {
                alert(err.message);
            });
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="ServiceModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="ServiceModalTitle">Guest Info</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <strong>Name</strong> <br />
                                        {this.props.guest.name}
                                    </div>
                                    <div className="col">
                                        <strong>Phone</strong> <br />
                                        {this.props.guest.phone}
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col">
                                        <strong>Service</strong> <br />
                                        {this.props.guest.service}
                                    </div>
                                    <div className="col">
                                        <strong>Stylist</strong> <br />
                                        {this.props.guest.stylist}
                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="col">
                                        <strong>Checked In At</strong> <br />
                                        {moment(this.props.guest.inAt).calendar()}
                                    </div>
                                    <div className="col">
                                        <strong>Serviced At</strong> <br />
                                        {moment(this.props.guest.servicedAt).calendar()}
                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="col">
                                        <strong>Add Notes: </strong> <br />
                                        <textarea className="form-control" name="notes" rows="4" onChange={this.handleChange} value={this.state.guest.notes || ''} />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#ServiceModal" onClick={this.remove}>Remove Guest</button>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#ServiceModal" onClick={this.checkout}>Checkout Guest</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ServiceModal;