import React from 'react';
import fire from '../Firebase';
import moment from 'moment';

const INITIAL_STATE = {
    stylists: [],
    guest: { stylist: ''}
}

class GuestInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        this.loadStylists();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.guest !== this.props.guest) {
            console.log(this.props.guest);
            this.setGuestToState();
        }
    }

    setGuestToState = () => {
        this.setState({ guest: this.props.guest });
    }

    handleChange = e => {
        this.setState({ guest: { ...this.state.guest, stylist: e.target.value } });
    }

    disableCheckIn = () => {
        return this.state.guest.stylist == null || this.state.guest.stylist.trim().length === 0;
    }

    dateString = (date) => {
        if(date === undefined) return;
        var m = new Date(date);
        var offset = new Date().getTimezoneOffset(); // minutes offset between local and UTC
        // m.setMinutes(-of)
        return m.getFullYear() +"/"+ (m.getMonth()+1) +"/"+ m.getDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes();
    }

    service = () => {
        fire.database().ref(`stores/${this.props.currentStoreId}/servicing/${this.state.guest.id}`)
        .set({
            servicedAt: new Date().getTime(),
            ...this.state.guest
        })
        .then(res => {
            this.remove();
        })
        .catch(err => {
            alert(err.message);
        });
    }

    remove = () => {
        fire.database().ref(`stores/${this.props.currentStoreId}/waiting/${this.state.guest.id}`)
        .remove()
        .catch(err => {
            alert(err.message);
        });
    }

    loadStylists() {
        fire.database().ref(`stores/${this.props.currentStoreId}/stylists`).on('value', snap => {
            var payload = snap.val();
            if (payload === null) {
                this.setState({ stylists: [] });
                return;
            }
            var stylists = Object.keys(payload).map(key => payload[key]);
            this.setState({ stylists: stylists });
        });
    }

    render() {
        return (
            <div>
                <div className="modal fade" id="guestInfoModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="guestInfoModalTitle">Guest Info</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col">
                                        <strong>Name</strong> <br />
                                        {this.props.guest.name}
                                    </div>
                                    <div className="col">
                                        <strong>Phone</strong> <br />
                                        {this.props.guest.phone}
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <div className="col">
                                        <strong>Service</strong> <br />
                                        {this.props.guest.service}
                                    </div>
                                    <div className="col">
                                        <strong>Stylist *</strong> <br />
                                        <select className="form-control" name="stylist" value={this.state.guest.stylist} onChange={this.handleChange}>
                                            <option value=''>Select Stylist</option>
                                            {this.state.stylists.map(stylist => <option key={stylist.name} value={stylist.name}>{stylist.name}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="col">
                                        <strong>Checked In At</strong> <br />
                                        {moment(this.props.guest.inAt).calendar()}
                                    </div>
                                    <div className="col">
                                        <strong>Serviced At</strong> <br />
                                        {this.props.guest.servicedAt}
                                    </div>
                                </div>
                                <br />

                                <div className="row">
                                    <div className="col">
                                        <strong>Notes</strong> <br />
                                        {this.props.guest.notes}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#guestInfoModal" onClick={this.remove}>Remove Guest</button>
                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#guestInfoModal" disabled={this.disableCheckIn()} onClick={this.service}>Service Guest</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default GuestInfoModal;