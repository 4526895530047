import React from 'react';
import fire from '../Firebase';
import './nav.css';
import logo from '../../logo.png';

class Nav extends React.Component {

    logout = () => {
        fire.auth().signOut();
    }

    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-md fixed-top navbar-dark">

                    <a className="navbar-brand mb-0 h1" href="/">
                        <img className="brand" src={logo} /> &nbsp;&nbsp;
                        {this.props.currentStoreName}
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item active">
                                <a className="nav-link" href="/logs">Guest Book</a>
                            </li>
                            <li className="nav-item active">
                                <a className="nav-link" href="/Settings">Settings</a>
                            </li>
                            {
                                this.props.user.role != null && (this.props.user.role === "business_admin" || this.props.user.role === "admin") ?
                                    <li className="nav-item active" >
                                        <a className="nav-link" href="/admin">Admin</a>
                                    </li> :
                                    null
                            }
                            <li className="nav-item active">
                                <a className="nav-link" href="/login" onClick={this.logout}>Logout</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Nav;