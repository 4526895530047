import React from 'react';
import NumberFormat from 'react-number-format';
import fire from '../Firebase';
import LastVisit from './lastVisit';

const INITIAL_STATE = {
    guest: {
        phone: '',
        name: '',
        service: '',
        stylist: ''
    },
    screen: 1,
    lastVisit: {},
    services: [],
    stylists: [],
}

class CheckInModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    storeRef = () => fire.database().ref(`stores/${this.props.currentStoreId}`);

    componentDidMount() {
        this.loadServices();
        this.loadStylists();
    }

    handleChange = e => {
        this.setState({ guest: { ...this.state.guest, [e.target.name]: e.target.value } });
    }

    handleSubmit = e => {
        e.preventDefault();
    }

    next = () => {
        this.storeRef().child(`guests/${this.state.guest.phone}`).once('value', snap => {
            var payload = snap.val();
            if (payload) {
                delete payload.visits;
                this.setState({ guest: { ...this.state.guest, name: payload.name, notes: payload.notes } });
                this.setState({ lastVisit: { ...payload } });
            } else {
                this.setState({lastVisit: {}})
            }
        })
        this.setState({ screen: 2 });
    }

    resetScreen = () => {
        this.setState({
            screen: 1,
            guest: { ...INITIAL_STATE.guest }
        });
    }

    disableCheckIn = () => {
        return this.state.guest.name.trim().length === 0 || this.state.guest.service.length === 0;
    }

    checkin = () => {
        this.storeRef().child(`waiting`).push({
            inAt: new Date().getTime(), ...this.state.guest
        }).catch(err => {
            alert(err.message);
        });
    }

    loadServices() {
        this.storeRef().child(`settings/services`).on('value', snap => {
            var payload = snap.val();
            if (payload === null) {
                this.setState({ services: [] });
                return;
            }
            var services = Object.keys(payload).map(key => payload[key]);
            this.setState({ services: services });
        });
    }

    loadStylists() {
        this.storeRef().child(`stylists`).on('value', snap => {
            var payload = snap.val();
            if (payload === null) {
                this.setState({ stylists: [] });
                return;
            }
            var stylists = Object.keys(payload).map(key => payload[key]);
            this.setState({ stylists: stylists });
        });
    }

    render() {
        return (
            <div>
                <button type="button" className="float-right btn btn-sm btn-primary" data-toggle="modal" data-target="#guestCheckInModal" onClick={this.resetScreen}> Add Guest</button>
                <div className="modal fade " id="guestCheckInModal" tabIndex="-1" role="dialog" aria-labelledby="guestCheckInModalTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="guestCheckInModalTitle">Add Guest</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {
                                this.state.screen === 1 ? (
                                    <div>
                                        <div className="modal-body">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="form-group">
                                                    <label>Guest Phone Number *</label>
                                                    <NumberFormat className="form-control" type='tel' format="(###) ### ####"
                                                        placeholder='(xxx) xxx xxxx' name="phone" autoFocus
                                                        onValueChange={(values) => {
                                                            const { formattedValue, value } = values;
                                                            this.setState({ guest: { ...INITIAL_STATE.guest, phone: value } })
                                                        }} />
                                                </div>
                                            </form>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-primary" onClick={this.next} disabled={this.state.guest.phone.length < 10}>Next</button>
                                        </div>
                                    </div>
                                ) : (
                                        <div>
                                            <div className="modal-body">
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label>Guest Name *</label>
                                                        <input type="text" name="name" onChange={this.handleChange} value={this.state.guest.name} className="form-control" autoFocus />
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Service *</label>
                                                        <select className="form-control" name="service" onChange={this.handleChange}>
                                                            <option value=''>Select Service</option>
                                                            {this.state.services.map(service => <option key={service.name} value={service.name}>{service.name}</option>)}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Stylist</label>
                                                        <select className="form-control" name="stylist" onChange={this.handleChange}>
                                                            <option value=''>Select Stylist</option>
                                                            {this.state.stylists.map(stylist => <option key={stylist.name} value={stylist.name}>{stylist.name}</option>)}
                                                        </select>
                                                    </div>
                                                </form>
                                                <LastVisit lastVisit={this.state.lastVisit} />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#guestCheckInModal" disabled={this.disableCheckIn()} onClick={this.checkin}>Check In</button>
                                            </div>
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default CheckInModal;