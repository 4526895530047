import React from 'react';

class NoGuests extends React.Component {
    render() {
        return (
            <div>
                <p></p>
                <div className='alert alert-warning'>
                    <strong>{this.props.message}</strong>
                </div>
            </div>
        )
    }
}

export default NoGuests;