import React from 'react';
import moment from 'moment';

class WaitingGuest extends React.Component {
    render() {
        return (
            <div>
                <p></p>
                <div className="card" data-toggle="modal" data-target="#guestInfoModal" onClick={() => this.props.onClick(this.props.guest)}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h5 className="card-title">{this.props.guest.name}</h5>
                            </div>
                            <div className="col">
                                {this.props.guest.service} <br />
                                <span style={{ 'display': this.props.guest.stylist ? 'block' : 'none' }}>{this.props.guest.stylist} <br /></span>
                                <span className="text-muted">
                                    <i className="fa fa-clock-o"></i>  {moment(this.props.guest.inAt).fromNow()}
                                </span>
                            </div>
                        </div>
                        <div className="float-right">

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WaitingGuest;