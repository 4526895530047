import React from 'react';
import fire from '../Firebase';
import WaitingGuest from './waitingGuest'
import NoGuests from '../NoGuest';
import CheckInModal from './checkInModal';
import GuestInfoModal from './infoModal';

class Waiting extends React.Component {
    constructor(props) {
        super(props);
        this.state = { guests: [], selectedGuest: {} };
    }

    componentDidMount() {
        fire.database().ref(`stores/${this.props.currentStoreId}/waiting`).on('value', snap => {
            var guests = [];
            var payload = snap.val();
            if (payload === null) {
                this.setState({ guests: [] });
                return;
            }
            Object.keys(payload).forEach(key => {
                var guest = { id: key, ...payload[key] };
                guests.push(guest);
                this.setState({ guests: guests });
            });
        });
    }

    selectGuest = (guest) => {
        this.setState({ selectedGuest: guest });
    }

    render() {
        return (
            <div className="">
                <div className="alert alert-dark">
                    <CheckInModal currentStoreId={this.props.currentStoreId} />
                    <h5>Waiting</h5>
                </div>
                {
                    this.state.guests.length === 0 ?
                        <NoGuests message="No guests waiting !!" /> :
                        this.state.guests.map(guest => <WaitingGuest key={guest.id} guest={guest} onClick={this.selectGuest} />)
                }
                <GuestInfoModal currentStoreId={this.props.currentStoreId} guest={this.state.selectedGuest} />
                <br />
                <br />
            </div>
        )
    }
}

export default Waiting;