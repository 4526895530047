import React from 'react';
import fire from '../Firebase';

const INITIAL_STATE = {
    total_guests: 0
}
class Today extends React.Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        var store = `stores/${this.props.currentStoreId}`;
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        fire.database().ref(`${store}/guestLogs`).orderByChild('inAt').startAt(today.getTime())
            .on('value', snap => {
                var payload = snap.val();
                if (payload === null) {
                    this.setState({ total_guests: 0 });
                } else {
                    var total_guests = Object.keys(payload).length;
                    this.setState({ total_guests: total_guests });
                }
            });
    }

    render() {
        return (
            <div>
                <div className="alert alert-dark">
                    <h5>Today</h5>
                </div>
                <div className="alert alert-success text-center">
                    <a className="alert-link" href="logs"> <h1> {this.state.total_guests} </h1> </a>
                    Guests
                </div>
            </div>
        )
    }
}

export default Today;